import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

const Pressekit = () => (
  <ContentContainer>
    <Box marginTop="37px">
      <Typography variant="h2">Pressekit</Typography>
    </Box>
    <Box margin="28px 0">
      <Typography paragraph variant="subtitle1">
        Her kan du downloade Min Klimaberegners logo og pressebilleder.
      </Typography>
      <Typography paragraph variant="subtitle1">
        Kommer snart.
      </Typography>
    </Box>
  </ContentContainer>
);

const ContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export default Pressekit;
