import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

const Hint = () => (
  <ContentContainer>
    <Box margin="28px 0">
      <Typography paragraph variant="subtitle1">
        Min Klimaberegner er din guide til en mere klimavenlig bolig. Du
        indtaster en adresse, hvorefter Min Klimaberegner henter data fra
        boligens energimærke. Du vælger selv, hvilke (og hvor mange)
        forbedringsforslag du ønsker at gennemføre for boligen, og gevinsten ved
        forbedringen vises i beregneren.
      </Typography>
      <Typography paragraph variant="subtitle1">
        Energimærket er lovpligtigt i forbindelse med salg af bolig i Danmark.
        Det er Energistyrelsen, der har ansvaret for energimærkningsordningen.
      </Typography>
      <Typography paragraph variant="subtitle1">
        Klimaberegneren viser ikke kun, hvad forbedringen koster for dig, og
        hvor stor din årlige energibesparelse er. Den viser også, hvad
        forbedringen betyder for klimaet. Det visualiseres ved at omregne din
        CO2-nedbringelse til antallet af træer, som årligt kan optage den samme
        mængde CO2.
      </Typography>
      <Typography paragraph variant="subtitle1">
        En beregningsmodel, der er udviklet i samarbejde med Palle Madsen, som
        er PhD. i skovdyrkning samt seniorforsker og ejer af InNovaSilva ApS.
        Han er desuden forfatter til Klimaskoven, der handler om skovens store
        betydning for klimaet.
      </Typography>
      <Typography paragraph variant="subtitle1">
        Min Klimaberegner er udviklet af DomuTech A/S.
      </Typography>
    </Box>
  </ContentContainer>
);

const ContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export default Hint;
