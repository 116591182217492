// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  InputAdornment,
  Box,
  Typography,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import SearchOutlineIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { withStyles } from '@material-ui/styles';
import { EnergyClass, HeaderMenu } from '../../components';
import { searchLocations } from '../../utils/api';
import FaqAccordion from '../../components/faq-modal/faq-accordion';
import FooterLogo from '../../components/footer/footer-logo';

const leaf = require('../../images/leaf_64x64.png');
const realBannerLogo = require('../../images/real-banner-logo.png');
const realHeader = require('../../images/real-header.jpg');

type Props = {
  classes: any,
  navigateDetail: Function,
};

const styles = theme => ({
  fab: {
    backgroundColor: '#003761',
    bottom: '15px',
    right: '15px',
    padding: '0',
    position: 'fixed',
    textTransform: 'capitalize',
    transition: 'all 0.4s ease-in-out',
    minHeight: '50px',
    width: '50px',
    '&:hover': {
      backgroundColor: '#003761',
      padding: '0 15px',
      width: '174px',
    },
    '&:hover p': {
      transition: 'all 0.4s ease-in-out',
      opacity: 1,
      width: '100%',
    },
  },
  uploadIcon: {
    color: '#FFF',
  },
  topIcon: {
    color: '#979797',
  },
  topMoreIcon: {
    color: '#979797',
    marginLeft: '6px',
  },
  ingenDataText: {
    color: '#A8ABB2',
    fontSize: '12px',
    fontStyle: 'italic',
  },
  searchTextField: {
    marginTop: '36px',
    '& fieldset': {
      borderRadius: 27,
      border: 'none',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#f4f4f8',
      borderRadius: 27,
      fontSize: '13px',
      paddingLeft: '10px',
    },
    '& .MuiInputBase-input': {
      color: 'initial',
      // fontWeight: '500',
      letterSpacing: 0.75,
    },
  },
  searchResultsText: {
    color: '#7B7D82',
    fontSize: '13px',
    fontWeight: 500,
    padding: '10px 15px',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchListContainer: {
    position: 'relative',
  },
  searchList: {
    paddingTop: 10,
    backgroundColor: '#fff',
    // boxShadow: '0 0.5px 1.2px 0 #1a000050',
    position: 'absolute',
    top: 0,
    minHeight: '100vh',
    left: 0,
    right: 0,
    zIndex: 5,
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.26)',
      top: '-15px',
      left: '35px',
      right: '35px',
      minHeight: 'auto',
      maxHeight: '395px',
      overflowY: 'scroll',
      width: 'auto',
    },
  },
  searchListItem: {
    cursor: 'pointer',
    padding: '10px 15px',
    transition: 'background-color 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  searchListItemText: {
    '& .MuiListItemText-primary': {
      color: '#202124',
      fontSize: 13,
      fontWeight: 500,
    },
    '& .MuiListItemText-secondary': {
      color: '#A5A8AF',
      fontSize: 12,
    },
  },
  searchListLocation: {
    backgroundColor: '#f6f6fa',
  },
  searchListLocationIcon: {
    color: '#7b7d82',
    fontSize: '19px',
  },
  inputIcon: {
    cursor: 'pointer',
    marginLeft: '5px',
    paddingRight: '16px',
    '& .MuiCircularProgress-root': {
      marginRight: '10px',
    },
  },
  headerTitle: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  headerSubtitle: {
    marginTop: '26px',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
});

function HomePage(props: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [resultsAfterSearch, setResultsAfterSearch] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const { classes, navigateDetail } = props;

  const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [delay, value]);

    return debouncedValue;
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    // Make sure we have a value (user has entered something in input)
    if (debouncedSearchTerm) {
      if (debouncedSearchTerm === searchTerm) {
        setIsSearching(true);
        searchLocations(debouncedSearchTerm).then(apiResults => {
          setIsSearching(false);
          setResults(apiResults);
          setResultsAfterSearch(true);
        });
      }
    } else {
      setIsSearching(false);
      setResultsAfterSearch(false);
      setResults([]);
    }
  }, [debouncedSearchTerm, searchTerm]);

  const onBackPress = () => {
    setSearchTerm('');
    setIsSearching(false);
  };

  return (
    <MainContainer>
      <TopBanner>
        <RealLogo src={realBannerLogo} alt="real-logo" />
        <img src={realHeader} alt="banner" style={{
          objectFit: "cover",
          objectPosition: "center",
          width: '100%',
          height: '400px'}} />
        <TopBannerText>
          Min Klimaberegner
        </TopBannerText>
      </TopBanner>
      <Header searchTerm={searchTerm}>
        <HeaderAnimationContainer searchTerm={searchTerm}>
          <HeaderTopContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={leaf} alt="Leaf" width="25px" height="25px" />
              <span
                style={{
                  color: '#003761',
                  fontSize: '20px',
                  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                  fontWeight: '400',
                  marginLeft: '7px',
                }}
              >
                Min Klimaberegner
              </span>
            </div>
            <div>
              <HeaderMenu onMenuItemClick={() => {}} />
            </div>
          </HeaderTopContainer>
          <Container maxWidth="sm">
            <Typography className={classes.headerSubtitle} variant="subtitle1">
              Hvad betyder energiforbedringer af boligen for klimaet og for din
              økonomi? Bliv klogere med klimaberegneren – som straks viser dig
              gevinsten ved de forbedringer, du ønsker.
            </Typography>
          </Container>
        </HeaderAnimationContainer>
        <Container maxWidth="sm">
          <TextField
            autoFocus
            fullWidth
            className={classes.searchTextField}
            style={{
              margin: searchTerm.length > 0 ? '0 0' : '36px 0 8px 0',
              transition: 'all 0.25s ease-in-out',
            }}
            placeholder="Indtast en adresse"
            margin="normal"
            variant="outlined"
            InputProps={{
              startAdornment:
                searchTerm.length > 0 ? (
                  <InputAdornment
                    className={classes.inputIcon}
                    onClick={onBackPress}
                  >
                    <ArrowBackIcon />
                  </InputAdornment>
                ) : null,
              endAdornment: (
                <InputAdornment className={classes.inputIcon}>
                  {isSearching && searchTerm.length > 0 && (
                    <CircularProgress size={20} />
                  )}
                  <SearchOutlineIcon />
                </InputAdornment>
              ),
            }}
            onChange={e => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </Container>
      </Header>

      <Container className={classes.searchListContainer} maxWidth="sm">
        {!isSearching &&
          !results.length &&
          resultsAfterSearch &&
          searchTerm.length > 0 && (
            <Box className={classes.searchList}>
              <Typography className={classes.searchResultsText}>
                Resultater
              </Typography>
              <List className={classes.root}>
                <ListItem
                  key="search-no-result"
                  className={classes.searchListItem}
                  onClick={() => {}}
                  disableGutters
                >
                  <ListItemAvatar>
                    <Avatar className={classes.searchListLocation}>
                      <HighlightOffIcon
                        className={classes.searchListLocationIcon}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.searchListItemText}
                    primary="Ingen resultater"
                    secondary="Vi fandt desværre ingen resultater der matcher din søgning."
                  />
                </ListItem>
              </List>
            </Box>
          )}
        {results.length > 0 && resultsAfterSearch && (
          <Box className={classes.searchList}>
            <Typography className={classes.searchResultsText}>
              Resultater
            </Typography>
            <List className={classes.root}>
              {results.map(result => (
                <ListItem
                  key={result.id}
                  className={classes.searchListItem}
                  onClick={() =>
                    // result.energyMarkLabel ? navigateDetail(result.id) : {}
                    navigateDetail(result.id)
                  }
                  disableGutters
                >
                  <ListItemAvatar>
                    <Avatar className={classes.searchListLocation}>
                      <LocationOnOutlinedIcon
                        className={classes.searchListLocationIcon}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.searchListItemText}
                    primary={`${result.street_name} ${result.street_number}`}
                    secondary={`${result.post_code} ${result.city}`}
                  />
                  <EnergyClass energyClass={result.energyMarkLabel} small />
                  <ListItemSeparator />
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        <Box marginTop="28px" marginBottom="15px">
          <Typography variant="subtitle1">Få svar på dine spørgsmål</Typography>
        </Box>
      </Container>
      <FaqAccordion />
      <FooterLogo />
    </MainContainer>
  );
}

const Header = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 6px 0 #1a000012;
  padding: 10px ${props => (props.searchTerm.length > 0 ? '0px' : '15px')};
  position: relative;
  transition: all 0.25s ease-in-out;
  @media all and (max-width: 600px) {
    z-index: 10;
  }
`;

const Logo = styled.img`
  max-width: 180px;
  width: 100%;
`;


const RealLogo = styled.img`
  position: absolute;
  top: 0;
  left: 18%;
  width: 100px;
  height: 100px;
  @media (max-width: 768px) {
    left: 15px;
  }
`;

const HeaderTopContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 37px;
`;

const TopBanner = styled(Box)`
  width: 100%;
  position: relative;
  background-color: #ffffff;
  padding-bottom: 10px;
`;

const TopBannerText = styled(Box)`
  align-items: center;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  font-size: 60px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const HeaderAnimationContainer = styled.div`
  & div {
    height: ${props => (props.searchTerm.length > 0 ? 0 : 'auto')};
    margin: 0;
    transition: all 0.25s ease-in-out 0.25s;

    @media all and (min-width: 768px) {
      margin: 0 auto;
    }
  }
  transform-origin: 50% 0;
  transform: scaleY(${props => (props.searchTerm.length > 0 ? 0 : 1)});
  opacity: ${props => (props.searchTerm.length > 0 ? 0 : 1)};
  transition: opacity 0.25s ease-in-out;
`;

const MainContainer = styled.div`
  background-color: #f6f6fa;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`;

const ListItemSeparator = styled.div`
  height: 1px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 70px);
  background-color: #eeeef2;
  right: 0;
`;

export default withWidth()(withStyles(styles)(HomePage));
