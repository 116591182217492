// @flow
import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type Props = {
  classes: any,
  children: any,
  heightLimit: number,
};

const styles = () => ({
  container: {
    position: 'relative',
  },
  changedWidth: {
    width: '90%',
  },
  showIcon: {
    position: 'absolute',
    top: 10,
    right: -40,
  },
  showMoreIcon: {
    transform: 'rotate(270deg)',
    transition: 'all .5s',
  },
  showLessIcon: {
    transform: 'rotate(90deg)',
    transition: 'all .5s',
  },
});

function ExpandTitle(props: Props) {
  const { classes, children, heightLimit } = props;

  const descriptionTextRef = useRef(null);

  const [showExpand, setShowExpand] = useState(false);
  const [showText, setShowText] = useState(false);
  const [textHeight, setTextHeight] = useState(0);

  useEffect(() => {
    if (descriptionTextRef.current.scrollHeight > heightLimit + 10) {
      setTextHeight(descriptionTextRef.current.scrollHeight);
      setShowExpand(true);
    }
    return () => {};
  }, [heightLimit]);

  useEffect(() => {
    if (showText) {
      if (showExpand) {
        descriptionTextRef.current.style.minHeight = `${heightLimit}px`;
      }
      descriptionTextRef.current.style.maxHeight = `${textHeight}px`;
      descriptionTextRef.current.style.transition = 'max-height .25s';
    } else {
      descriptionTextRef.current.style.maxHeight = `${heightLimit}px`;
      descriptionTextRef.current.style.transition = 'max-height .25s';
    }
  }, [heightLimit, showExpand, showText, textHeight]);

  return (
    <div
      className={`${classes.container} ${
        showExpand ? classes.changedWidth : ''
      }`}
    >
      <div
        onClick={() =>
          showExpand && (showText ? setShowText(false) : setShowText(true))
        }
        style={{ overflow: 'hidden', cursor: 'default' }}
        ref={descriptionTextRef}
      >
        <div>{children}</div>
      </div>
      {showExpand && (
        <div className={classes.showIcon}>
          <ExpandMoreIcon
            className={showText ? classes.showLessIcon : classes.showMoreIcon}
            onClick={() => (showText ? setShowText(false) : setShowText(true))}
          />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(ExpandTitle);
