import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import handleViewport from 'react-in-viewport';
import { HeaderMenu } from '../../components';
import Trees from '../../components/trees/trees';
import Moneybags from '../../components/moneybags/moneybags';

const logo = require('../../images/real_logo.svg');

const InViewportTrees = handleViewport(Trees, { threshold: 1 });
const InViewportMoneybags = handleViewport(Moneybags, { threshold: 1 });

const styles = () => ({
  container: {
    display: 'flex',
    height: '100%',
    padding: '26px 20px 26px 20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    border: '1px solid #e8e8e8',
  },
  treeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const treeArray = [
  [1, 2],
  [2, 23],
  [3, 65],
  [4, 200],
  [5, 330],
  [6, 470],
  [7, 640],
];
const bagsArray = [
  [1, 400],
  [2, 660],
  [3, 1450],
  [4, 4000],
  [5, 7500],
  [6, 34000],
];

const TreesBagsProportion = props => {
  const { classes } = props;

  return (
    <MainContainer>
      <Header>
        <HeaderTopContainer>
          <div>
            <Logo src={logo} alt="logo" width="300px" height="auto" />
          </div>
          <div>
            <HeaderMenu onMenuItemClick={() => {}} />
          </div>
        </HeaderTopContainer>
      </Header>

      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        className={classes.container}
      >
        <Grid container className={classes.treeContainer}>
          {treeArray.map(data => (
            <InViewportTrees
              treesData={{
                treesAnimation: data[0],
                noOfTrees: data[1],
              }}
            />
          ))}
        </Grid>
        <Grid container className={classes.treeContainer}>
          {bagsArray.map(data => (
            <InViewportMoneybags
              moneybagsAnimation={data[0]}
              noOfMoney={data[1]}
            />
          ))}
        </Grid>
      </Grid>
    </MainContainer>
  );
};

const Header = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 6px 0 #1a000012;
  padding: 10px 15px;
  position: relative;
  transition: all 0.25s ease-in-out;
  @media all and (max-width: 600px) {
    z-index: 10;
  }
`;

const Logo = styled.img`
  max-width: 180px;
  width: 100%;
`;

const HeaderTopContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 37px;
`;

const MainContainer = styled.div`
  background-color: #f6f6fa;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
`;

export default withStyles(styles)(TreesBagsProportion);
