// @flow
import React from 'react';
import styled from 'styled-components';
import { Box, Link, Typography, withStyles } from '@material-ui/core';

type Props = {
  classes: any,
};

const styles = () => ({
  link: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#13A970',
    fontSize: '12px',
  },
});

function VilkarOgBetingelser(props: Props) {
  const { classes } = props;
  return (
    <ContentContainer>
      <Box marginTop="37px">
        <Typography variant="h2">Generelt</Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          www.klimaberegner.realmaeglerne.dk leveres af HUSSYN.PRO.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Hvis du oplever problemer med vores website, bedes du kontakte
          kundeservice.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Tlf.: 3175 1100 - åbningstiden er mandag til fredag 09.00 - 16.00
        </Typography>
        <Typography variant="subtitle1">Mail: info@hussyn.pro</Typography>
      </Box>
      <Box marginTop="37px">
        <Typography variant="h2">Ansvarsfraskrivelse</Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Formålet med Min Klimaberegner er at formidle værdien af at foretage
          rentable energiforbedringer på en let og forståelig måde.
          Klimaberegneren henter data fra foreliggende energimærke(r) og
          eksponerer informationerne på anden måde. Data kan være med afvigelser
          ift. det originale energimærke og må ikke bruges som
          beslutningsgrundlag for eks. køb af ejendom eller for at foretage
          energibesparende foranstaltninger, her skal der søges faglig
          rådgivning. HUSSYN.PRO ApS kan ikke drages til ansvar for forhold, der
          måtte opstå som følge af brugen af informationer fra Min
          Klimaberegner. Klimaberegneren og hele dens indhold er leveret uden
          nogen form for garanti. Alle priser er vejledende og er 100 % baseret
          på data fra boligens Energimærkningsrapport.
        </Typography>
      </Box>
      <Box marginTop="37px">
        <Typography variant="h2">Beregningsmodel</Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Beregningsmodellen er udviklet med assistance fra Palle Madsen. Han er
          tidligere professor ved Skovskolen, Københavns Universitet. I dag
          virker han som PhD. i skovdyrkning samt seniorforsker og ejer af
          InNovaSilva ApS. Min Klimaberegner tager udgangspunkt i, hvor meget
          CO2 et gennemsnitligt bøgetræ binder om året, over en levetid på 100
          år. Vi arbejder med følgende model:
        </Typography>
        <ul>
          <li>
            <Typography variant="subtitle1">
              1 træ binder 3,5 kg CO2 om året, svarende til 0,0035 ton.
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              100 træer binder 350 kg CO2 om året, svarende til 0,35 ton.
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
              286 træer binder 1.000 kg CO2 om året, svarende til 1 ton.
            </Typography>
          </li>
        </ul>
      </Box>
      <Box marginTop="37px">
        <Typography variant="h2">Cookie- og privatlivspolitik</Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">Introduktion</Typography>
        <Typography variant="subtitle1">
          Når du besøger vores website indsamles der oplysninger om dig, som
          bruges til at tilpasse og forbedre vores indhold og til at øge værdien
          af de annoncer, der vises på siden. Hvis du ikke ønsker, at der
          indsamles oplysninger, bør du slette dine cookies (se vejledning) og
          undlade videre brug af websitet. Nedenfor har vi uddybet, hvilke
          informationer der indsamles, deres formål og hvilke tredjeparter, der
          har adgang til dem.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">Cookies</Typography>
        <Typography variant="subtitle1">
          Websitet anvender ”cookies”, der er en tekstfil, som gemmes på din
          computer, mobil el. tilsvarende med det formål at genkende den, huske
          indstillinger, udføre statistik og målrette annoncer. Cookies kan ikke
          indeholde skadelig kode som f.eks. virus. Det er muligt at slette
          eller blokere for cookies. Se vejledning:
          <Link
            className={classes.link}
            href="http://minecookies.org/cookiehandtering"
            target="_blank"
            rel="noopener"
          >
            http://minecookies.org/cookiehandtering
          </Link>{' '}
          Hvis du sletter eller blokerer cookies vil annoncer kunne blive mindre
          relevante for dig og optræde hyppigere. Du kan desuden risikere at
          websitet ikke fungerer optimalt samt at der er indhold, du ikke kan få
          adgang til.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography paragraph variant="subtitle1">
          Personoplysninger
        </Typography>
        <Typography variant="subtitle1">Generelt</Typography>
        <Typography variant="subtitle1">
          Personoplysninger er alle slags informationer, der i et eller andet
          omfang kan henføres til dig. Når du benytter vores website indsamler
          og behandler vi en række sådanne informationer. Det sker f.eks. ved
          alm. tilgang af indhold, hvis du tilmelder dig vores nyhedsbrev,
          deltager i konkurrencer eller undersøgelser, registrerer dig som
          bruger eller abonnent, øvrig brug af services eller foretager køb via
          websitet. Vi indsamler og behandler typisk følgende typer af
          oplysninger: Et unikt ID og tekniske oplysninger om din computer,
          tablet eller mobiltelefon, dit IP-nummer, geografisk placering, samt
          hvilke sider du klikker på (interesser). I det omfang du selv giver
          eksplicit samtykke hertil og selv indtaster informationerne behandles
          desuden: Navn, telefonnummer, e-mail, adresse og betalingsoplysninger.
          Det vil typisk være i forbindelse med oprettelse af login.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">Sikkerhed</Typography>
        <Typography variant="subtitle1">
          Vi har truffet tekniske og organisatoriske foranstaltninger mod, at
          dine oplysninger hændeligt eller ulovligt bliver slettet,
          offentliggjort, fortabt, forringet eller kommer til uvedkommendes
          kendskab, misbruges eller i øvrigt behandles i strid med lovgivningen.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">Formål</Typography>
        <Typography variant="subtitle1">
          Oplysningerne bruges til at identificere dig som bruger og vise dig de
          annoncer, som vil have størst sandsynlighed for at være relevante for
          dig, at registrere dine køb og betalinger, samt at kunne levere de
          services, du har efterspurgt, som f.eks. at fremsende et nyhedsbrev.
          Herudover anvender vi oplysningerne til at optimere vores services og
          indhold.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">Periode for opbevaring</Typography>
        <Typography variant="subtitle1">
          Oplysningerne opbevares i det tidsrum, der er tilladt i henhold til
          lovgivningen, og vi sletter dem, når de ikke længere er nødvendige.
          Perioden afhænger af karakteren af oplysningen og baggrunden for
          opbevaring. Det er derfor ikke muligt at angive en generel tidsramme
          for, hvornår informationer slettes.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Videregivelse af oplysninger
        </Typography>
        <Typography variant="subtitle1">
          Data om din brug af websitet, hvilke annoncer, du modtager og evt.
          klikker på, geografisk placering, køn og alderssegment m.v.
          videregives til tredjeparter i det omfang disse oplysninger er kendt.
          Du kan se hvilke tredjeparter, der er tale om, i afsnittet om
          ”Cookies” ovenfor. Oplysningerne anvendes til målretning af
          annoncering. Vi benytter herudover en række tredjeparter til
          opbevaring og behandling af data. Disse behandler udelukkende
          oplysninger på vores vegne og må ikke anvende dem til egne formål.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Videregivelse af personoplysninger som navn og e-mail m.v. vil kun
          ske, hvis du giver samtykke til det. Vi anvender kun databehandlere i
          EU eller i lande, der kan give dine oplysninger en tilstrækkelig
          beskyttelse.
        </Typography>
        <Typography variant="subtitle1">Indsigt og klager</Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Du har ret til at få oplyst, hvilke personoplysninger, vi behandler om
          dig. Du kan desuden til enhver tid gøre indsigelse mod, at oplysninger
          anvendes. Du kan også tilbagekalde dit samtykke til, at der bliver
          behandlet oplysninger om dig. Hvis de oplysninger, der behandles om
          dig, er forkerte har du ret til at de bliver rettet eller slettet.
          Henvendelse herom kan ske til: info@hussyn.pro. Hvis du vil klage over
          vores behandling af dine personoplysninger, har du også mulighed for
          at tage kontakt til{' '}
          <Link
            className={classes.link}
            href="http://www.datatilsynet.dk/"
            target="_blank"
            rel="noopener"
          >
            Datatilsynet
          </Link>
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">Udgiver</Typography>
        <Typography gutterBottom variant="subtitle1">
          Websitet ejes og publiceres af:
        </Typography>
        <Typography variant="subtitle1">Hussyn.pro</Typography>
        <Typography variant="subtitle1">Drejergangen 3B</Typography>
        <Typography variant="subtitle1">2690 Karlslunde</Typography>
        <Typography variant="subtitle1">Email: info@husysn.pro</Typography>
      </Box>
      <Box marginTop="37px">
        <Typography variant="h2">Kontaktoplysninger</Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Hvis du har nogen spørgsmål eller øvrige henvendelser angående vores
          vilkår, bedes du skrive til info@hussyn.pro.
        </Typography>
      </Box>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export default withStyles(styles)(VilkarOgBetingelser);
