import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core';

const realBannerLogo = require('../../images/real-banner-logo.png');


const styles = theme => ({
});

function FooterLogo(props) {
  return (
    <FooterContainer>
      <img src={realBannerLogo} alt="real-logo" width={200} height={200} />
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  background-color: #fff;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default withStyles(styles)(FooterLogo);
