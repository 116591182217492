// @flow
export const toDanishCurrencyString = (
  value: number,
  decimalPlaces: number = 0
) => {
  const p = value.toFixed(decimalPlaces).split('.');

  let result = p[0].replace(/(\d)(?=(\d{3})+\b)/g, '$1.');
  if (decimalPlaces > 0 && p[1] && p[1].length > 0) {
    result = `${result},${p[1]}`;
  }

  return result;
};

export const calculateNoOfTrees = (co2Saving: number) => {
  const result = {
    treesAnimation: 1,
    noOfTrees: Math.ceil(co2Saving / 0.0035),
  };

  switch (true) {
    case co2Saving >= 0.0001 && co2Saving <= 0.01:
      result.treesAnimation = 1;
      break;
    case co2Saving >= 0.01 && co2Saving <= 0.1:
      result.treesAnimation = 2;
      break;
    case co2Saving >= 0.1 && co2Saving <= 0.3:
      result.treesAnimation = 3;
      break;
    case co2Saving >= 0.3 && co2Saving <= 0.8:
      result.treesAnimation = 4;
      break;
    case co2Saving >= 0.8 && co2Saving <= 1.3:
      result.treesAnimation = 5;
      break;
    case co2Saving >= 1.3 && co2Saving <= 2.0:
      result.treesAnimation = 6;
      break;
    case co2Saving >= 2.0:
      result.treesAnimation = 7;
      break;
    default:
      result.noOfTrees = 0;
  }

  return result;
};

export const calculateMoneybagsAnimation = (
  x: number
): 1 | 2 | 3 | 4 | 5 | 6 => {
  const intX = parseFloat(x);
  switch (true) {
    case intX >= 0 && intX <= 200:
      return 1;
    case intX > 200 && intX <= 500:
      return 2;
    case intX > 500 && intX <= 1000:
      return 3;
    case intX > 1000 && intX <= 5000:
      return 4;
    case intX > 5000 && intX <= 10000:
      return 5;
    case intX > 10000 && intX <= 100000:
      return 6;
    default:
      return 1;
  }
};
