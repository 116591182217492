/* global google */
import React, { useState } from 'react';
import { Marker } from 'react-google-maps';
import styled from 'styled-components';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import smallMarker from '../../images/marker.png';
import largeMarker from '../../images/largemarker.png';
import { toDanishCurrencyString } from '../../utils/util';

const MarkerList = ({ data, expandedId, toggleExpandedId }) => {
  return (
    <>
      {data.map((marker, idx) => (
        <CustomMarker
          key={marker.id}
          marker={marker}
          expanded={expandedId === marker.id}
          toggleExpandedId={toggleExpandedId}
        />
      ))}
    </>
  );
};

const CustomMarker = ({ marker, expanded, toggleExpandedId }) => {
  return (
    <Marker
      defaultVisible={false}
      position={{ lat: marker.lat, lng: marker.lng }}
    >
      {expanded ? (
        <InfoBox
          options={{
            pixelOffset: new google.maps.Size(-140, -120),
            closeBoxURL: ``,
            disableAutoPan: true,
            enableEventPropagation: true,
            zIndex: 101,
            boxStyle: {
              width: '280px',
              overflow: 'hidden',
            },
          }}
        >
          <div
            style={{ height: '130px' }}
            onClick={e => toggleExpandedId(marker.id)}
          >
            <img
              style={{ width: '320px', transform: 'translateX(-20px)' }}
              src={largeMarker}
              alt="price"
            />
            <div
              style={{
                width: '230px',
                height: '82px',
                transform: 'translate(25px, -159px)',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
                color: 'gray',
              }}
            >
              <div
                style={{
                  width: '80px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <img
                  style={{
                    width: '82px',
                    height: '82px',
                    borderRadius: '5px',
                  }}
                  src={marker.urlbillede}
                  alt="house"
                />
              </div>
              <div
                style={{
                  width: '160px',
                  overflow: 'hidden',
                  paddingLeft: '10px',
                }}
              >
                <div
                  style={{
                    fontSize: 14,
                    marginBottom: '5px',
                    fontWeight: 500,
                    color: '#202124',
                  }}
                >
                  Til Salg:
                </div>
                <div
                  style={{
                    fontSize: 14,
                    marginBottom: '1px',
                    fontWeight: 500,
                    color: '#202124',
                  }}
                >
                  {marker.streetAddress}
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: '5px',
                    color: '#616266',
                  }}
                >
                  {marker.postalAddress}
                </div>
                {/* <p style={{ fontSize: 11 }}>{marker.maeglernavn}</p> */}
                <div
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    color: '#13A970',
                  }}
                >
                  {toDanishCurrencyString(+marker.kontantpris)} kr.
                </div>
              </div>
            </div>
          </div>
        </InfoBox>
      ) : (
        <InfoBox
          options={{
            pixelOffset: new google.maps.Size(-70, -40),
            closeBoxURL: ``,
            disableAutoPan: true,
            enableEventPropagation: true,
            zIndex: 15,
            boxStyle: {
              width: '140px',
              height: '65px',
            },
          }}
        >
          <Container>
            <img
              style={{ width: '140px', height: '62px' }}
              src={smallMarker}
              alt="price"
            />
            <PriceContainer onClick={e => toggleExpandedId(marker.id)}>
              {toDanishCurrencyString(+marker.kontantpris)} kr.
            </PriceContainer>
          </Container>
        </InfoBox>
      )}
    </Marker>
  );
};

const PriceContainer = styled.div`
  width: 120px;
  height: 30px;
  transform: translate(10px, -60px);
  justify-content: center;
  align-items: center;
  display: flex;
  color: #13a970;
  font-weight: bold;
  font-size: 15px;
`;

const Container = styled.div`
  width: 140px;
  height: 62px;
  overflow: none;
`;

export default MarkerList;
