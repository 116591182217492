// @flow
import { toDanishCurrencyString } from './util';

import { apiBase, googleMapApiKey } from '../config';

export const searchLocations = (searchTerm: string) => {
  return fetch(`${apiBase}/v1/searchList`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      szKeyword: searchTerm,
    }),
  })
    .then(r => r.json())
    .then(r => {
      if (r && r.response && Array.isArray(r.response.address)) {
        return r.response.address;
      }
      return [];
    })
    .catch(() => {
      return [];
    });
};

export const getLocationDetails = (propertyId: string) => {
  return fetch(`${apiBase}/v1/propertyDetails`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      idProperty: propertyId,
    }),
  })
    .then(response => {
      return response.json();
    })
    .then(response => {
      const result: any = response.response;
      result.id = propertyId;

      const _all = {
        id: -1,
        title: 'Alle',
        ShortText: '',
        LongText: '',
        profitable: '',
        Investment: 0,
        MoneySaving: 0,
        CO2Saving: 0,
        _greenImprovementValue: 100,
        _isAllTab: true,
      };

      if (
        result &&
        Array.isArray(result.profilt) &&
        result.profilt.length > 0
      ) {
        let profitableProfilt = result.profilt.filter(
          data => data.profitable === 'true'
        );

        let nonProfitableProfilt = result.profilt.filter(
          data => data.profitable === 'false'
        );

        const reduceProfilt =
          profitableProfilt.length > 0
            ? profitableProfilt
            : nonProfitableProfilt;

        const total = reduceProfilt.reduce((currentTotal, improvement) => {
          const addValue = parseInt(improvement.CO2Saving * 100, 10);

          _all.Investment += parseInt(improvement.Investment, 10);
          _all.MoneySaving += parseInt(improvement.MoneySaving, 10);
          _all.CO2Saving += parseFloat(improvement.CO2Saving);

          return currentTotal + addValue;
        }, 0);

        result.profilt = result.profilt
          .map(improvement => {
            const calculatedImprovement = {
              ...improvement,
              _greenImprovementValue: Math.round(
                (parseInt(improvement.CO2Saving * 100, 10) * 100) / total
              ),
            };
            return calculatedImprovement;
          })
          .sort((a, b) =>
            a._greenImprovementValue < b._greenImprovementValue ? 1 : -1
          );

        profitableProfilt = result.profilt.filter(
          data => data.profitable === 'true'
        );
        nonProfitableProfilt = result.profilt.filter(
          data => data.profitable === 'false'
        );

        result.profilt = [...profitableProfilt, ...nonProfitableProfilt];

        const noProfitable = profitableProfilt.length === 0;

        _all.profitable = noProfitable ? 'false' : 'true';

        _all.ShortText = noProfitable
          ? 'Disse energiforbedringer anbefales kun i forbindelse med renovering'
          : 'Alle rentable forbedringer';

        const alleInitialText = noProfitable
          ? 'Udføres alle renoveringer, vil det give en årlig besparelse på '
          : `Udføres alle de rentable forbedringsforslag, vil det koste <b>${toDanishCurrencyString(
              _all.Investment,
              0
            )} kr.</b> og give en årlig besparelse på `;

        _all.LongText = `${alleInitialText}<b>${toDanishCurrencyString(
          _all.MoneySaving,
          0
        )} kr.</b> og <b>${toDanishCurrencyString(
          _all.CO2Saving,
          2
        )} ton CO2.</b>`;

        result.profilt.unshift(_all);
      }

      return Promise.resolve(result);
    });
};

export const getLocationDetailsByCaseNumber = (caseNumber: string) => {
  return fetch(`${apiBase}/v1/fetchPropertyByCaseNo`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      caseNumber,
    }),
  })
    .then(response => {
      return response.json();
    })
    .then(response => {
      const result: any = response.response;

      const _all = {
        id: -1,
        title: 'Alle',
        ShortText: 'Alle rentable forbedringer',
        LongText: '',
        profitable: 'true',
        Investment: 0,
        MoneySaving: 0,
        CO2Saving: 0,
        _greenImprovementValue: 100,
        _isAllTab: true,
      };

      if (
        result &&
        Array.isArray(result.profilt) &&
        result.profilt.length > 0
      ) {
        const total = result.profilt.reduce((currentTotal, improvement) => {
          const addValue = parseInt(improvement.CO2Saving * 100, 10);

          _all.Investment += parseInt(improvement.Investment, 10);
          _all.MoneySaving += parseInt(improvement.MoneySaving, 10);
          _all.CO2Saving += parseFloat(improvement.CO2Saving);

          return currentTotal + addValue;
        }, 0);

        _all.LongText = `Udføres alle de rentable forbedringsforslag, vil det koste <b>${toDanishCurrencyString(
          _all.Investment,
          0
        )} kr.</b> og give en årlig besparelse på <b>${toDanishCurrencyString(
          _all.MoneySaving,
          0
        )} kr.</b> og <b>${toDanishCurrencyString(
          _all.CO2Saving,
          2
        )} ton CO2.</b>`;

        result.profilt = result.profilt
          .map(improvement => {
            const calculatedImprovement = {
              ...improvement,
              _greenImprovementValue: Math.round(
                (parseInt(improvement.CO2Saving * 100, 10) * 100) / total
              ),
            };
            return calculatedImprovement;
          })
          .sort((a, b) =>
            a._greenImprovementValue < b._greenImprovementValue ? 1 : -1
          );

        result.profilt.unshift(_all);
      }

      return Promise.resolve(result);
    });
};

export const saveConversion = (data: any) => {
  return fetch(`${apiBase}/v1/conversionForm`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(response => {
      return response.json();
    })
    .then(response => {
      const result: any = response.response;

      return Promise.resolve(result);
    });
};

export const resolveAddress = (address: string, geocoder: Function) => {
  return new Promise<any>((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      const response = {
        lat: 0,
        lng: 0,
      };

      if (status === 'OK') {
        const firstLocation = results[0];
        if (firstLocation) {
          response.lat = firstLocation.geometry.location.lat();
          response.lng = firstLocation.geometry.location.lng();
        }
      }
      resolve(response);
    });
  });
};

export const findNearbyLocations = (
  postCode: string = '2620',
  geocoder: Function
) => {
  return fetch(`${apiBase}/v1/fetchMapPropertyByPostCode`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ postCode }),
  })
    .then(response => {
      return response.json();
    })
    .then(response => {
      const result: any = response.response.map(item => {
        const address = `${`${item.Vejnavn} ${item.Husnummer} ${item.Sidedoer}`.trim()}, ${
          item.Postnummer
        } ${item.Bynavn}`;
        return resolveAddress(address, geocoder).then(({ lat, lng }) => {
          return Promise.resolve({
            ...item,
            address,
            lat,
            lng,
          });
        });
      });

      return Promise.all(result);
    });
};

const latGridRows = 5;
const lngGridColumns = 5;
const maxBoxElements = 1;
export const findLocationsByBoundingBox = (bb: any) => {
  const latGridSize = (bb.maxLat - bb.minLat) / latGridRows;
  const lngGridSize = (bb.maxLng - bb.minLng) / lngGridColumns;
  const requestPayload = {
    minLat: bb.minLat,
    maxLat: bb.maxLat,
    minLng: bb.minLng,
    maxLng: bb.maxLng,
    limit: 200,
  };
  return fetch(`${apiBase}/v1/fetchAddressByLatLongRange`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bb),
  })
    .then(response => {
      return response.json();
    })
    .then(response => {
      let result = [];
      if (
        response.code === '200' &&
        response.response &&
        response.response.status === 'OK' &&
        Array.isArray(response.response.addressList)
      ) {
        result = response.response.addressList.map(item => {
          const lat = parseFloat(item.lat);
          const lng = parseFloat(item.lng);

          const latBox = parseInt((lat - bb.minLat) / latGridSize, 10);
          const lngBox = parseInt((lng - bb.minLng) / lngGridSize, 10);
          return {
            ...item,
            lat,
            lng,
            boxLatLng: `${latBox}-${lngBox}`,
          };
        });

        const boxCounter = {};

        result = result.filter(item => {
          const key = item.boxLatLng;
          if (!boxCounter[key]) {
            boxCounter[key] = 1;
          } else if (boxCounter[key] <= maxBoxElements) {
            boxCounter[key] += 1;
          } else {
            return false;
          }
          return true;
        });
      }
      return result;
    });
};
