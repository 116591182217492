/* global google */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, useState, useEffect } from 'react';
import { compose, withProps } from 'recompose';
import useForm from 'react-hook-form';
import styled from 'styled-components';
import { Skeleton } from '@material-ui/lab';
import {
  Button,
  IconButton,
  Grid,
  TextField,
  Typography,
  Tab,
  Tabs,
  Box,
  Snackbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withScriptjs,
  withGoogleMap,
} from 'react-google-maps';
import InputMask from 'react-input-mask';

import { saveConversion, resolveAddress } from '../../utils/api';
import { Map } from '../../components';
import { googleMapApiKey, websiteUrl } from '../../config';

import { ReactComponent as RealLogo } from '../../images/logo-real.svg';

type Props = {
  classes: any,
  id: number,
  address: string,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#202124',
  },
  leftContainerWrap: {
    maxWidth: 800,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  leftContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    minWidth: 400,
    minHeight: '100vh',
  },
  addressContainer: {
    padding: 40,
  },
  rightContainer: {
    flex: 'auto',
    maxWidth: 2000,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerLogo: {
    marginBottom: 40,
  },
  formContainer: {},
  row: {
    width: '100%',
    display: 'block',
  },
  halfColumn: {
    width: '50%',
    display: 'block',
  },
  textField: {},
});

function LocationMapComponent(props: Props) {
  const { classes, id, address } = props;

  const postcode = parseInt(address.split(',')[1].trim(), 10);

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [nearbyLocations, setNearbyLocations] = useState([]);

  const [formSaveSuccess, setFormSaveSuccess] = React.useState(false);

  const { handleSubmit, register, errors, reset } = useForm();

  useEffect(() => {
    resolveAddress(address, new google.maps.Geocoder())
      .then(result => {
        setLat(result.lat);
        setLng(result.lng);
      })
      .catch(err => {});
  }, [address]);

  const onSubmit = values => {
    const apiPayload = {
      ...values,
      propertylink: `${websiteUrl}/detail/${id}`,
    };
    saveConversion(apiPayload);
    setFormSaveSuccess(true);
    reset();
  };

  const handleCloseFormSaveSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFormSaveSuccess(false);
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;
    let { selection } = newState;

    // remove space if entered by user
    if (userInput === ' ') {
      value = oldState.value;

      selection = {
        start: oldState.selection.start,
        end: oldState.selection.end,
      };
    }

    return {
      ...newState,
      value,
      selection,
    };
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={formSaveSuccess}
        autoHideDuration={6000}
        onClose={handleCloseFormSaveSuccess}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id">
            Din anmodning er sendt til Realmæglerne. Tak.
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseFormSaveSuccess}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={12} md={6} className={classes.leftContainerWrap}>
          <div className={classes.leftContainer}>
            <div className={classes.addressContainer}>
              <div className={classes.headerLogo}>
                <RealLogo width="40px" />
              </div>
              <HeaderHeadline>Bestil gratis salgsvurdering</HeaderHeadline>
              <HeaderText>
                RealMæglerne kan også sælge din bolig. Se salgspriser på
                ejendomme i dit område på kortet til højre og udfyld formularen
                for at få at vide, hvor meget din bolig er værd.
              </HeaderText>
              <form
                className={classes.formContainer}
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Label>Adresse:</Label>
                  <Input
                    name="address"
                    type="text"
                    placeholder="Byagervej 83, 8330 Beder"
                    ref={register({
                      required: {
                        value: true,
                        message: 'Please specify address',
                      },
                      minLength: {
                        value: 3,
                        message: 'Please specify minimum 3 characters',
                      },
                    })}
                    defaultValue={decodeURIComponent(address)}
                  />
                  <ErrorMessage>
                    {errors.address && errors.address.message}
                  </ErrorMessage>
                </Row>
                <Row>
                  <Label>Navn:</Label>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Kristoffer Hansen"
                    ref={register({
                      required: { value: true, message: 'Please specify name' },
                      minLength: {
                        value: 3,
                        message: 'Please specify minimum 3 characters',
                      },
                    })}
                  />
                  <ErrorMessage>
                    {errors.name && errors.name.message}
                  </ErrorMessage>
                </Row>
                <Row>
                  <HalfRowLeft key="email">
                    <Label>E-mail:</Label>
                    <Input
                      name="email"
                      type="text"
                      placeholder="kristofferhansen@gmail.com"
                      ref={register({
                        required: {
                          value: true,
                          message: 'Please specify the e-mail address',
                        },
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Please specify a valid e-mail address',
                        },
                      })}
                    />
                    <ErrorMessage>
                      {errors.email && errors.email.message}
                    </ErrorMessage>
                  </HalfRowLeft>
                  <HalfRowRight key="telephone">
                    <Label>Telefon:</Label>
                    <InputMask
                      mask="99 99 99 99"
                      maskChar=" "
                      value={props.value}
                      onChange={props.onChange}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                    >
                      {inputProps => (
                        <Input
                          {...inputProps}
                          name="phone"
                          placeholder="11 22 33 44"
                          ref={register({
                            required: {
                              value: true,
                              message: 'Please specify the phone',
                            },
                            pattern: {
                              value: /^([0-9]{2} ){3}[0-9]{2}$/,
                              message: 'Please specify a valid phone number',
                            },
                          })}
                        />
                      )}
                    </InputMask>
                    <ErrorMessage>
                      {errors.phone && errors.phone.message}
                    </ErrorMessage>
                  </HalfRowRight>
                  <br style={{ clear: 'both' }} />
                </Row>
                <SubmitButton type="submit">
                  Bestil gratis salgsvurdering
                </SubmitButton>
                <InfoText>
                  Du accepterer at blive kontaktet af nærmeste RealMægler, som
                  udarbejder en gratis salgsvurdering på din bolig.
                </InfoText>
              </form>
            </div>
          </div>
        </Grid>
        {lat !== 0 && lng !== 0 && (
          <Grid item xs={12} sm={12} md={6} className={classes.rightContainer}>
            <Map
              lat={lat}
              lng={lng}
              address={address}
              markers={nearbyLocations}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}

const Header = styled.div`
  background-color: #fff;
  box-shadow: 0 4px 6px 0 #1a000012;
  padding: 10px 15px;
  position: relative;
`;

const Logo = styled.img`
  max-width: 180px;
  width: 100%;
`;

const HeaderHeadline = styled.p`
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  margin-bottom: 20px;
  text-align: left;
  color: #202124;
`;

const HeaderText = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  line-height: 26px;
  margin-bottom: 30px;
  color: #616266;
`;

const InfoText = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #616266;
`;

const Label = styled.label`
  display: block;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  font-size: 13px;
  height: 40px;
  border-radius: 4px;
  border: 0.5px solid #dcdce2;
  padding-left: 10px;
  border-style: solid;
  box-sizing: border-box;
  :focus {
    outline: 1.5px solid #ccc;
  }
`;

const ErrorMessage = styled.div`
  width: 100%;
  color: red;
  margin-top: 3px;
  height: 10px;
  font-size: 12px;
`;

const Row = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 30px;
`;

const HalfRowLeft = styled.div`
  width: calc(50% - 10px);
  margin-right: 10px;
  float: left;
  display: block;
`;

const HalfRowRight = styled.div`
  width: calc(50% - 10px);
  margin-left: 10px;
  float: left;
  display: block;
`;
const SubmitButton = styled.button`
  background: #003761;
  border-radius: 28px;
  border: 0;
  color: #ffffff;
  height: 50px;
  width: 100%;
  text-align: center;
  font-size: 15px;
  margin-top: 30px;
  :hover {
    cursor: pointer;
    background: #264e7a;
  }
`;

const LocationMapPage = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs
)(props => <LocationMapComponent {...props} />);

export default withStyles(styles)(LocationMapPage);
