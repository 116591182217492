// @flow
import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

export default function OmKlimaberegner() {
  return (
    <ContentContainer>
      <Box marginTop="37px">
        <Typography variant="h2">Om Min Klimaberegner</Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Min Klimaberegner guider dig til en mere klimavenlig bolig ved at
          præsentere forbedringsforslag fra boligens energimærke på en ny og
          visuel måde. Samtidig ser du, hvor mange penge forbedringen sparer dig
          for i boligens energiforbrug, og du ser, hvor meget CO2 du skåner
          klimaet for. Energimærket er lovpligtigt i forbindelse med salg af
          bolig. Det er Energistyrelsen, der har ansvaret for
          energimærkningsordningen.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Min Klimaberegner samler og præsenterer data på en nem og overskuelig
          måde. Dermed kan du også se præcis, hvad dit bidrag gør for klimaet og
          din økonomi, når du energioptimerer boligen.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Du kan gøre en stor forskel for klimaet, når du mindsker boligens
          energiforbrug.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Klimaberegneren vil ikke alene vise prisen på forbedringen og den
          årlige energibesparelse ved samme. Den vil også visualisere i træer,
          hvad CO2-nedbringelsen svarer til. Altså; hvor mange træer kan årligt
          optage den samme mængde CO2, som du nedbringer dit aftryk med ved
          energiforbedringen? Det kunne fx være 410 kg (0,41 ton) CO2 årligt.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Skoven har ifølge Palle Madsen, PhD. i skovdyrkning samt seniorforsker
          og ejer af InNovaSilva ApS, en nøglerolle i forhold til klimaet, fordi
          træer ved deres fotosyntese kan optage CO2 fra luften og lagre CO2 som
          kulstof i skoven og træmaterialerne og ikke mindst erstatte
          energikrævende materialer som beton og stål.
        </Typography>
      </Box>
      <Box margin="28px 0">
        <Typography variant="subtitle1">
          Skovrejsning på 11 procent af Danmarks landareal kan nedbringe
          CO2-aftrykket med hele 30 procent, hvis man både planter vækstkraftige
          træer og anvender træet kløgtigt.
        </Typography>
      </Box>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
`;
