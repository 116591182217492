const apiBase =
  process.env.REACT_APP_ENV === 'staging'
    ? 'https://stagingapi.klimaberegneren.dk/staging_panel/api'
    : 'https://api.klimaberegneren.dk/api_panel/api';

const googleMapApiKey =
  process.env.REACT_APP_ENV === 'staging'
    ? 'AIzaSyBDbzn_XFtIkr1b_pieLxobiiQRKfKNl_U'
    : 'AIzaSyD-e250uQdHEp7_-9xl2B3sLXZUU-aE_OM';

const websiteUrl =
  process.env.REACT_APP_ENV === 'staging'
    ? 'http://staging-co2calculator.s3-website.eu-central-1.amazonaws.com'
    : 'https://klimaberegner.realmaeglerne.dk';

export { apiBase, googleMapApiKey, websiteUrl };
