// @flow
import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type Props = {
  classes: any,
  children: any,
  heightLimit: number,
};

const styles = () => ({
  showIcon: {
    marginTop: '15px',
    textAlign: 'center',
  },
  showMoreIcon: {
    transform: 'rotate(0deg)',
    transition: 'all .4s',
  },
  showLessIcon: {
    transform: 'rotate(180deg)',
    transition: 'all .4s',
  },
  gradient: {
    backgroundImage:
      'linear-gradient(to bottom, rgba(255,255,255,0.1) , rgba(255,255,255,0.80))',
    height: '40px',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
});

function ExpandText(props: Props) {
  const { classes, children, heightLimit } = props;

  const descriptionTextRef = useRef(null);

  const [showExpand, setShowExpand] = useState(false);
  const [showText, setShowText] = useState(false);
  const [textHeight, setTextHeight] = useState(0);

  useEffect(() => {
    if (descriptionTextRef.current.scrollHeight > heightLimit) {
      setTextHeight(descriptionTextRef.current.scrollHeight);
      setShowExpand(true);
    }
    return () => {};
  }, [heightLimit]);

  useEffect(() => {
    if (showText) {
      if (showExpand) {
        descriptionTextRef.current.style.minHeight = `${heightLimit}px`;
      }
      descriptionTextRef.current.style.maxHeight = `${textHeight}px`;
      descriptionTextRef.current.style.transition = 'max-height .4s';
    } else {
      descriptionTextRef.current.style.maxHeight = `${heightLimit}px`;
      descriptionTextRef.current.style.transition = 'max-height .4s';
    }
  }, [heightLimit, showExpand, showText, textHeight]);

  return (
    <div>
      <div
        style={{ position: 'relative', overflow: 'hidden' }}
        ref={descriptionTextRef}
      >
        <div>{children}</div>
        <div className={`${showExpand && !showText ? classes.gradient : ''}`} />
      </div>
      {showExpand && (
        <div
          onClick={() => (showText ? setShowText(false) : setShowText(true))}
          className={classes.showIcon}
        >
          <ExpandMoreIcon
            className={showText ? classes.showLessIcon : classes.showMoreIcon}
          />
        </div>
      )}
    </div>
  );
}

export default withStyles(styles)(ExpandText);
