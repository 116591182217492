// @flow
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Skeleton } from '@material-ui/lab';
import { Typography, Tab, Tabs, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import {
  HeaderMenu,
  EnergyClass,
  Trees,
  LocationDetailsTab,
  showMap,
} from '../../components';
import { calculateNoOfTrees } from '../../utils/util';
import { getLocationDetails } from '../../utils/api';

const LoadingIndicator = require('../../images/loading_green.svg');

type State = {
  currentTab: number,
  data: any,
  loading: true | false,
  showButton: true | false,
};

type Props = {
  id: number,
};

const StyledTabs = withStyles({
  root: {
    maxWidth: 'calc(100% - 260px)',
    '@media (max-width: 600px)': {
      maxWidth: '100%',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#003761',
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
    padding: '0',
    height: '3px',
    width: '100%',
  },
  scrollButtonsDesktop: {
    display: 'inline-flex',
  },
})(props => <Tabs {...props} />);

const StyledTab = withStyles({
  root: {
    flex: '0',
    padding: '0',
    margin: '0 12.5px',
    '&:first-child': {
      margin: '0 12.5px 0 22px',
    },
    '&$selected': {
      color: '#003761',
    },
    maxWidth: 'max-content',
    minWidth: 'min-content',
    '@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none)': {
      /* IE10+ specific styles go here */
      // minWidth: min-content doesnt work with ie11 ---> looking for other solution
    },
    textTransform: 'capitalize',
  },
  wrapper: {
    whiteSpace: 'nowrap',
    width: 'auto',
  },
  textColorPrimary: {
    color: '#A8ABB2',
    fontSize: '13px',
    fontWeight: 400,
    padding: '0',
    textTransform: 'capitalize',
  },
  selected: {
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
})(props => {
  const { classes, isProfitable } = props;
  return (
    <Tab style={{ opacity: isProfitable ? 1 : 0.4 }} disableRipple {...props} />
  );
});

class LocationResultDetailsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTab: 0,
      data: {},
      loading: true,
      showButton: false,
    };
  }

  componentWillMount = () => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      const { id } = this.props;
      getLocationDetails(id.toString()).then(data => {
        this.setState({
          data,
          loading: false,
        });
      });
    }, 1000);

    setTimeout(() => {
      this.setState({ showButton: true });
    }, 3000);
  };

  handleTabChange = (e: any, newValue: number) => {
    this.setState({
      currentTab: newValue,
    });
  };

  renderTabPanel = (data: any) => {
    const { currentTab, loading } = this.state;

    if (
      !data.profilt ||
      (Array.isArray(data.profilt) && data.profilt.length < 1)
    ) {
      console.log('data: ', data);

      return (
        <Box
          alignItems="center"
          display="flex"
          color="#999"
          flexDirection="column"
          fontSize="36px"
          minHeight="100vh"
          minWidth="100vw"
        >
          <Trees
            playImmediately
            hideCounter
            treesData={calculateNoOfTrees(6)}
          />
          <Box marginTop="40px" padding="10px" fontSize="16px">
            <Typography align="center" variant="h2">
              {!data.energyMarkLabel
                ? 'Der foreligger desværre ikke et gyldigt energimærke på ejendommen.'
                : 'Der findes ingen forbedringsforslag på adressen'}
            </Typography>
          </Box>
          <Box marginTop="5px" padding="10px">
            <Typography align="center" variant="subtitle1">
              {!data.energyMarkLabel
                ? 'Vidste du, at der ved salg af din ejendom udarejds et energimærke?'
                : 'Boligens energimærke indeholder ikke nogen rentable forbedringsforslag.'}
            </Typography>
          </Box>
        </Box>
      );
    }

    return data.profilt.map((tab, index) => {
      return (
        <ContentContainer>
          <LocationDetailsTab
            loading={loading}
            index={index}
            currentTab={currentTab}
            data={data}
          />
        </ContentContainer>
      );
    });
  };

  render() {
    const { currentTab, loading, data } = this.state;

    return (
      <Container>
        <TopSideContainer>
          <Header>
            <AddressHeader>
              {loading ? (
                <Skeleton variant="rect" width="40px" height="40px" />
              ) : (
                <EnergyClass energyClass={data.energyMarkLabel} />
              )}
              <AddressContainer>
                {loading ? (
                  <>
                    <Skeleton variant="rect" width="100px" height="20px" />
                    <Skeleton variant="rect" width="100px" height="15px" />
                  </>
                ) : (
                  <>
                    <Typography variant="h2">{`${data.street_name} ${data.street_number}`}</Typography>
                    <CityText variant="subtitle1">{`${data.post_code} ${data.city}`}</CityText>
                  </>
                )}
              </AddressContainer>
            </AddressHeader>

            <HeaderMenu onMenuItemClick={() => {}} />
          </Header>

          <TabsNavigationContainer>
            {loading ||
              (data.profilt.length > 0 && (
                <TabsContent>
                  <StyledTabs
                    maxWidth="1200px"
                    value={currentTab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textcolor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable tabs"
                  >
                    {loading
                      ? [1, 2, 3, 4].map(v => (
                          <Box key={`tabs-skeleton-${v}`} margin="0 12.5px">
                            <Skeleton width="70px" />
                          </Box>
                        ))
                      : data.profilt.map(tabs => (
                          <StyledTab
                            isProfitable={tabs.profitable === 'true'}
                            label={tabs.title || '(no title)'}
                          />
                        ))}
                  </StyledTabs>
                  <ShowMapButton
                    showButton={this.state.showButton}
                    onClick={() => showMap(data)}
                    id="ghost-button"
                  >
                    FÅ EN GRATIS SALGSVURDERING
                  </ShowMapButton>
                </TabsContent>
              ))}
          </TabsNavigationContainer>
        </TopSideContainer>

        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            minHeight="80vh"
            justifyContent="center"
            flexDirection="column"
            margin="20px 15px 0 15px"
          >
            <LoadingIndicatorContainer>
              <img src={LoadingIndicator} alt="Loading Indicator" />
            </LoadingIndicatorContainer>
            <Box marginTop="15px">
              <Typography variant="subtitle1">Læser energimærke</Typography>
            </Box>
          </Box>
        ) : (
          this.renderTabPanel(data)
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  background-color: #eee;
  min-height: 100vh;
`;

const TopSideContainer = styled(Box)`
  // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const Header = styled(Box)`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  padding: 20px 18px 0px 28px;
  max-width: 1200px;
`;

const AddressHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 19px;
`;

const TabsNavigationContainer = styled.div`
  background-color: #fff;
  padding: 20px 0 0 0;
`;

const TabsContent = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  margin: auto;
`;

const CityText = styled(Typography)`
  line-height: 14px !important;
`;

const LoadingIndicatorContainer = styled.div`
  max-width: 56px;
  width: 100%;

  & > img {
    transform-origin: 50% 50%;
    animation: rotate 0.5s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;

const ShowMapButton = styled.button`
  text-decoration: none;
  display: inline-block;
  margin: 0 10px;
  border: 1.5px solid #003761;
  background-color: white;
  white-space: nowrap;
  text-align: center;
  color: #003761;
  font-family: Roboto, Helvetica, Arial sans-serif;
  font-size: 14px;
  height: 40px;
  width: 260px;
  cursor: pointer;
  opacity: ${props => (props.showButton ? 1 : 0)};
  transition: all 1s ease-in;
  @media (max-width: 600px) {
    display: none;
  }
`;

export default LocationResultDetailsPage;
